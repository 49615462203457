@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Overlock:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap');
*{
    
     padding: 0;
    margin: 0; 
    overflow-x: clip;
    
    
     
     
}
 
.drifter_logo img{
     margin: 20px;
     display: block;
  margin-left: auto;
  margin-right: auto;
   
}
.hero1{
    background-color:#FEFAF1;
    
}
.hero_container{
    width: 85%;
    height: auto;
    background-color: #116A7B;
    padding: 30px;
     
    margin-left: 80px;
    border: none;
    border-radius: 15px;
    

}
.contact-input{
    display: flex;
    flex-direction: row;
    margin: auto;
}
.btn{
    margin-top: 5px;
    
}
.hero_container> h1{
    display: block;
    color: whitesmoke;
    
    font-family: "Montserrat", sans-serif;
    font-size: 65px;
    /* margin-left: 300px; */
    text-align: center;
    margin-top: 50px;

}
.hero_container p{
    /* display: block; */
    color: #D6D46D;
    
  
    font-family: "Montserrat", sans-serif;
    
    font-size: 25px;
    margin: 80px;
    line-height: 29px;
    text-align: center;
    margin-top: 20px;

}
.hero_container button{
    height: 50px;
    width: 120px;
     
    background-color: #F5DD61;
    border: none;
    border-radius: 10px;
    margin-left: 45%;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
   cursor: pointer;
}
.hero2{
    background-color:#FEFAF1;
}

.hero2heading {
    text-align: center;
    padding-top: 100px;
    color: #51829B;
    font-family:  'OverLock';
     font-size: 25px;
}
.hero2heading img{
    margin-top: -290px;
    margin-left: 270px;
    position: relative;
  
    left:40px;
    top: -25px;
}
span{
    color: black;
}
.hero2right{
    width: 40%;
    height:350px;
    margin-top: 30px;
    margin-left: 90px;
    border-radius: 12px;
    background-color: #AAD7D9;
}
.hero2right p{
    display: block;
    width: 80%;
    text-align: center;
    padding: 50px;
    font-family: "Montserrat", sans-serif;
font-size: 18px;
    line-height: 25px;
    padding-top: 80px;
    font-weight: 500
    ;
}
.hero2left{
    margin-left: 190px;
    transition: transform .5s;
}
.hero2left:hover{
    transform: scale(1.2);
}
.hero2contaner{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
}
.wave{
    margin-top: -150px;
}
#firstairplain{
    position: relative;
  top: -400px;
  left: 20px;
  animation: bounce2 4s ease infinite;

}
@keyframes bounce2 {
	0%, 20%, 50%, 80%, 100% {transform: translateY(-10px);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}
#calender{
    position: relative;
    top: -250px;
    left: 270px;
    animation: bounce2 4s ease infinite;
}
#earth1{
    position: relative;
  top: -400px;
 
  left: 400px;
  animation: bounce2 4s ease infinite;
}
#earth2{
    position: relative;
    top: -310px;
    left: 450px;
    animation: bounce2 4s ease infinite;
}
#rocket2{
    position: relative;
  top: -240px;
  left: 468px;
  animation: bounce2 4s ease infinite;
}
.features{
    background-color: #FEFAF1;
}
.featureshead{
    text-align: center;
    /* padding-top: 100px; */
    color: #51829B;
    font-family:  'OverLock';
     font-size: 25px;
     padding-top: 100px
      
}
.featureshead img{
 position: relative;
  left: 20px;
  top: -30px;
}

.featurepic{
    height: 300px;
    width: 500px;
    background-image: linear-gradient(to left,#79AC78,#D0E7D2);
   padding: 20px;
   margin: 20px;
   margin-left: 90px;
   border-radius: 18px;
   border: none;
}
.featurepic img{
    position: relative;
  top: -160px;
  transition: transform .5s; 

}
.featurepic img:hover{
    transform: scale(1.1);
}

.feature{
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    margin-top: 100px;

}
.featureSecond{
    display: flex;
    flex-direction: row;
    
    margin-top: 100px;

}
#feature2{
    position: relative;
  top: -220px;
}
#feature3{
    position: relative;
  top: -180px;
}
.features_list h3{
    text-align: start;
    margin-left: 132px;
     color: #580808; 
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    
}
.features_list p{
    color: #1E1D1C;
    font-family: "Montserrat", sans-serif;
    padding: 10px;
    text-align: start;
    display: block;
    width: 70%;
    margin-left: 120px;
    line-height: 25px;

}
.features_list{
    padding-top: 20px;
}
.Working{
    background-color: #FEFAF1;
}
.working_head{
    
    text-align: center;
    padding-top: 100px;
    color: #51829B;
    font-family:  'OverLock';
     font-size: 25px;
}
.working_head img{
    position: relative;
  top: -20px;
  left: -10px;

}
.workingpic{
    height: 300px;
    width: 500px;
    background-image: linear-gradient(to left,#FFF5ED,#FDE6D7);
   padding: 20px;
   margin: 20px;
   margin-left: 90px;
   border-radius: 18px;
   border: none;

}
.work{
    display: flex;
    
    flex-direction: row;
    margin-top: 100px;

}
.btn {
    background-color: #FAE5B7;
    color: #000; /* Text color */
    height: 45px;
    width: 80px;
    border: none;
    border-radius: 5px; /* Rounded corners */
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth color transition */
  }
  
  .btn:hover {
    background-color: #F1D19D; /* Change color on hover */
  }

 
.working_info p{
    color: #1E1D1C;
    font-family: "Montserrat", sans-serif;
    padding: 10px;
    text-align: start;
    display: block;
    width: 70%;
    margin-left: 120px;
    line-height: 25px;

}
.working_info h3{
    text-align: start;
    margin-left: 132px;
    color: #580808;
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
}
.working_info{
    width: 60%;
    padding-top: 90px;
}
.workingpic img{
    position: relative;
  top: 19px;
  left: 90px;
  transition: transform .6s;
}
.workingpic img:hover
{
    transform: scale(1.1)
}
.faq{
    background-color:#FFF5ED;
}
.faq_heading {
    
    text-align: center;
    padding-top: 100px;
    color: #51829B;
    font-family:  'OverLock';
     font-size: 25px;

}
 .faq_heading img{
    position: relative;
top: -25px;
left: 200px;
}
 .faq_heading p{
    text-align: center;
    font-family: "Montserrat", sans-serif;
    color: black;

 }
 .faq_contaner{
    width: 70%;
    margin-left: 220px;
    margin-top: 30px;
    font-family:  "Montserrat", sans-serif;
    padding: 50px;
 }
 .faq_wave{
    margin-top: -560px;
 }
 .contact-contaner{
    background-color: #00574E;
    padding: 20px;
    margin: -120px 50px 25px 50px;
    
    border: none;
    border-radius: 22px;
    height: 400px;
    width: 90%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    
 }
 .contact{
    background-color: #FFF5ED;
    
 }
  .contact_left h1{
    font-size: 55px;
  font-family: overlock;
  color: white;
  margin: 10px;
  }
  .contact_left p {
    font-size: 16px;
    color: white;
    /* display: block; */
    font-family: overlock;
    width: 40%;
    margin: 10px;
    line-height: 22px;
   
  }
 .input{
    height: 45px;
  width: 200px;
  margin: 10px;
  border: none;
  border-radius: 12px;

  }
  #spam{
    margin-top: 40px;
  font-size: 12px;
  font-family: overlock;
  color: white;
  }
 .footer{
    background-color: black;
    margin-top: -20px;
    height: auto;
 }
.footer_contaner{
    display: flex;
    flex-direction: row;
    color :white;
    justify-content: space-between;
    padding: 40px;
}
.footer_contaner h3{
    font-family: overlock;
    font-weight: 500;
    font-size: 20px;
    padding-top:16px;
  
}
/*  */
.marquee-container {
    width: 100%;
    overflow: hidden;
  }
  
  .marquee {
    white-space: nowrap;
    display: inline-block;
  }
  .marque{
    overflow: hidden;
    /* margin: 60px; */
    padding: 30px;
    background-image: linear-gradient(to left,#FEF7ED,#FDEEE6);
    /* transform: rotate(-1deg); */
     }
  .Marquee_list h2{
    
    margin: 10px;
    /* background-image: linear-gradient(to left,#FEF7ED,#FDEEE6); */

    margin-left: 50px;
    font-family: OverLock;

  }
  .image_slider{
    background-color: #FEFAF1;
    height: 700px;
  }
  .image_sliderContent{
    background-color: #FEF7E9;
    height: 500px;
    width: 90%;
    padding: 20px;
  margin: -90px 50px 25px 50px;
  border: none;
  border-radius: 22px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
 }
 .slider-marquee{
    margin-top: -100px;
    height: 700px;
    margin-left: 30px;
    /* padding-bottom: 40px; */
 }
 /*  */
 /* App.css */
.table-container {
    margin-top: 20px;
  }
  
  table {
    width: 80%;
    height: 500px;
    border-collapse: collapse;
    margin-left: 150px;
   
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    font-family: overlock;
  }
  
  th {
    background-color: #f2f2f2;
    text-align: center;
  }
  
  th:first-child,
  td:first-child {
    text-align: left;
  }
  
  tr:nth-child(even) {
    background-color: #ffff;
  }
  
  tr:hover {
    background-color: #ddd;
  }
  .tick {
    color: green;
   
    border-radius: 50%;
  }
  
  .cross {
    color: red;
   

    border-radius: 50%;
  }
  .compare_head{
    background-color: #FEFAF1;
  }
  .compare_head h1{
    text-align: center;
    padding-top: 100px;
    color: #51829B;
    font-family:  'OverLock';
     font-size: 55px;
     margin-top: -120px;

  }
  .compare_head img{
    position: relative;
    top: -35px;
  left: 800px;
  }
  .compare_head p{
    text-align: center;
    font-family: "Montserrat", sans-serif;
    color: black;
    margin-bottom: 30px;

  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90%; /* Set maximum width */
    width: 600px;
    height: 500px;
    background-color:#FF5BAE;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    color: black;
    background-color: white;
    border: none;
    font-size: 20px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: background-color 0.3s ease;
  }
  
  .close-btn:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
 
  @media screen and (max-width: 768px) {
    .popup {
      height:90%;
      width: 90%;
    
       

    }
    .popup-content h2 {
      font-size: 2px;
      
    }
    .popup-content p {
    
    font-size: 14px;
   
     
  
    }
    .popup-content a {
    
    font-size: 18px;
    }

  }
  .popup-content h2 {
    font-size: 30px;
    padding: 10px;
    color: white;
    font-family: overlock;
    padding: 10px;
  }
  .popup-content p {
    color: #1d0712;
  font-size: 16px;
  line-height: 22px;
  font-family: overlock;
  padding: 10px;

  }
  .popup-content a {
    margin: 10px;
  color: #6f0746 ;
  font-size: 22px;
  }
  
  
  
  
 
  