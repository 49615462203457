@media screen and (min-width:481px) and (max-width:620px) {
 
    .hero_container {
        height: auto;
  width: auto;
  margin: 10px;
  margin-bottom: 0;
    }
    .hero_container h1{
        width: 100%;
    font-size: 50px  ;
   margin: auto;
    text-align: center;
}
.hero_container p{
    width: 100%;
  margin: auto;
  text-align: center;
  margin-top: 5%;
  font-size: 20px;
}
.hero_container button{
    height: 30px;
  width: 100px;
  margin-top: 5%;
  margin-left: 35%;
  font-size: 18px;
}
.hero2heading h1{
    font-size: 35px;
    margin-top: -10%;
  }
  .hero2heading img{
    margin-left: 35%;
    width: 150px;
    top: -20px;
  }
  .hero2right{
    height: auto;
    width: auto;
    margin: 10px;
  }
  .wave{
    display: none;
  }
  .hero2left img{
  
    display: none;
  }
  .hero2right p{
    font-size: 15px;
  margin-left: -3%;
   
  padding-top: 30px;
  text-align: center;
  }
  .compare_head h1{
    font-size: 35px;
    margin-top: -0.1px;
  }
  .compare_head p{
    padding-left: 10px;
    padding-right: 10px;
  }
  .compare_head img{
    width: 150px;
    top: -20px;
  left: 250px;
  padding-left: 10px;
  }
 
  .featureshead h1{
    font-size: 35px;
  }
  .featureshead img{
    width: 150px;
  }
  .feature{
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .featureSecond{
    display: flex;
    flex-direction: column;

  }
  .featurepic{
    margin: auto;
  width: 80%;
  height: 300px;
  }
  .features_list p{
    margin-left: auto;
  margin-right: auto;
  width: 80%;
  }
  
  #feature2{
    height: 450px;
    top: -130px;
  }
  .image_slider{
    height: 400px;
    padding-top: 50px;
    padding-right: 20px;

  }
  .image_sliderContent{
    height: 300px;
    padding: 10px;
    margin: -20px 20px 5px 20px;
     justify-content: space-between;
    
  }
  .features_list h3{
    text-align: center; 
    width: 50%;
  }
  
  .slider-marquee{
    height: 600px;
    width: 40%;
  }


  
  .slider-marquee{
    margin-top: -300px;
    height: 300px;
    width: 33%;
    margin: 5px;
    
  }
  .slider-marquee img{
  
    height: 300px;
    width: 80%;
    margin-left: 30px;
    
   
  }
  .working_head h1{
    font-size: 35px;
  }
  .working_head img{
    width: 150px;
    top: -30px;
  left: 70px;

  }
  .work{
    flex-wrap: wrap;
    flex-direction: column-reverse;
    
  }
  .work1{
    display: flex;
    flex-direction: column;

  }
  .faq_heading h1{
    font-size: 35px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: -10%;
  }
  .faq_heading img{
     visibility: hidden;
  }
  .faq_heading p{
    font-size: 20px;
  }
  .faq_contaner{
    width: 100%;
  height: auto;
  margin: 10px 10px 0 10px;
   
  padding: 1px;
  }
  .contact-contaner {
    height: auto;
  
    width: auto;
  
    margin: auto;
  
  }
  .faq{
    padding: 10px;

  }
  .contact_left > h1 {
    font-size: 35px;
  }
  .contact_left > p{
    width: 90%;
  }
  .working_info{
    width: 100%;
   
  }
  .workingpic{
    margin: auto;
    margin-bottom: 15px;
    width: 80%;
    height: 300px;
  }
  .working_info{
    margin-top: -10px;
  }
  .working_info h3{
    margin-left: 10%;
    padding-right: 10px;

  }
  .working_info p{
    margin-left: 10%;
  }
  .working_contaner{
    margin-top: -70px;
  }
  .contact-input{
    width: 90%;
  }
  /*  */
  table {
    width: 100%;
    margin-left:auto
  }

  th, td {
    padding: 8px;
    font-size: 14px;
  }

  /* Stack table cells vertically */
  tbody, tr, th, td {
    display: block;
  }

  tr {
    border: none;
    margin-bottom: 10px;
  }

  td {
    border-bottom: 1px solid #ddd;
  }

  td:last-child {
    border-bottom: none;
  }

  td:before {
    /* Add data-label attribute with column header text */
    content: attr(data-label);
    font-weight: bold;
    float: left;
    text-transform: uppercase;
  }


  
   input{
  height: 45px !important;
  margin: 10px !important;
  border-radius: 12px;
 }
  #susta{
    margin-left: 112px;
  }
  .social{
    margin: 10px;
  }
  /*  */
  .contact_left {
    margin-left: 10%;
    width: 100%;
  
  }
  .contact_left p{
    width: 80%;
  }
}